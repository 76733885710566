.ql-editor {
    width: 100%;
    min-height: 400px;
    // border: 1px solid #696cff;
    p {
        margin: 0 !important;
    }
}

.quill {
    // border-radius: 15px !important;
    overflow: hidden;
    border: 1px solid #696cff;
    .ql-toolbar.ql-snow {
        border-bottom: 1px solid #696cff;
        padding: 5px;
    }
}
