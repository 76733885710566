.addNews-page {
    width: 1000px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
    padding: 50px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .row {
      width: 800px;
      margin: 20px 0;
      .title {
        margin: 0 0 20px;
        font-size: 20px;
        font-weight: 600;
      }
      input {
        width: 100%;
        border: 1px solid rgb(232, 237, 242);
        padding: 15px;
        border-radius: 10px;
        font-size: 18px;
        font-weight: 600;
        color: #697a8d;
        &:focus {
          border: 2px solid rgb(69, 103, 216);
          outline: none;
        }
      }
      .file {
        position: relative;
        padding: 30px;
        color: #6c6e6e;
        border: 3px dashed rgb(232, 237, 242);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .file-input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          &:hover {
            cursor: pointer;
          }
        }
        .p {
          margin: 10px 0 0;
          letter-spacing: 2px;
          line-height: 1.5;
          font-weight: 600;
        }
        .img {
          height: 50px;
          img {
            height: 100%;
          }
        }
      }
      .control {
        font-size: 16px;
        font-weight: 600;
        color: #697a8d;
        width: 100%;
        height: 50px;
      }
      .statuBar {
        width: 100px;
        height: 50px;
        border: 2px solid rgb(69, 103, 216);
        border-radius: 25px;
        // background-color: #fff;
        box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-p {
          width: 100%;
          height: 100%;
          color: rgb(69, 103, 216);
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 25px;
          &:hover {
            cursor: pointer;
          }
        }
  
        .btn {
          position: absolute;
          top: 3px;
          left: 3px;
          width: 40px;
          height: 40px;
          border-radius: 25px;
          background-color: rgb(69, 103, 216);
          box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
          transition: all 0.3s ease-in-out;
        }
        .move {
          transform: translateX(50px);
        }
      }
    }
    .footer {
      margin: 50px 0;
      display: flex;
      justify-content: center;
    }
  }
  .icon {
    transition: all 0.3s;
    &:hover {
      transform: scale(1.2);
      cursor: pointer;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .addNews-page {
      width: 100%;
      padding: 0 30px;
      .row {
        width: 100%;
      }
    }
  }
  