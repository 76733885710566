.footerBar {
  position: relative;
  img {
    width: 100%;
  }
  .footer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 100px;
    .logo {
      font-size: 25px;
      font-weight: 600;
      &:hover {
        cursor: pointer;
      }
    }
    .ul {
      display: flex;
      align-items: center;
      li {
        padding: 0 20px;
        margin: 0 10px;
        font-size: 20px;
        transition: all 0.3s;
        &:hover {
          cursor: pointer;
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .footerBar {
    .footer {
      padding: 30px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .logo {
        margin: 0 0 10px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .footerBar {
    .footer {
      .ul {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .footerBar {
    width: 100%;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
    }
    .footer {
      .ul {
        li {
          width: 100%;
          text-align: center;
          margin: 0 0 10px;
        }
      }
    }
  }
}
