.story-page {
  width: 100%;
  .sec1 {
    position: relative;
    width: 100%;
    z-index: 2;
    img {
      width: 100%;
    }
    .sec1-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      letter-spacing: 1.5px;
      .title {
        font-size: 80px;
        line-height: 1.5;
        font-weight: 600;
      }
      .p {
        margin: 20px 0 0;
        font-size: 24px;
        line-height: 1.5;
        color: #4d546f;
      }
      .btn {
        width: 200px;
        margin: 30px auto 0;
        background: linear-gradient(6deg, #17ffd3 0%, #23e3ee 100%);
        color: #091337;
        padding: 15px 0;
        border-radius: 5px;
        font-size: 20px;
        font-weight: 600;
        transition: all 0.3s;
        &:hover {
          background: linear-gradient(186deg, #17ffd3 0%, #23e3ee 100%);
          cursor: pointer;
          transform: scale(1.15);
        }
      }
    }
  }
  .sec2 {
    position: relative;
    margin: -300px 0 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    img {
      width: 80%;
    }
  }
  .sec3 {
    text-align: center;
    letter-spacing: 1.5px;
    padding: 50px 0;
    position: relative;
    .title {
      font-size: 60px;
      font-weight: 500;
      margin: 0 0 50px;
    }
    .little-title {
      line-height: 2;
      font-size: 24px;
      margin: 0 0 20px;
    }
    .row {
      width: 1400px;
      margin: 50px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .col {
        display: flex;
        .img {
          width: 100px;
          height: 100px;
          border-radius: 5px;
          background: #008dec;
          box-shadow: 0px 18px 25px 0px rgba(0, 141, 236, 0.1);
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .txt {
          text-align: left;
          font-size: 24px;
          line-height: 1.5;
          b {
            font-weight: 500;
          }
        }
      }
    }
  }
  .sec4 {
    margin: 50px 0;
    position: relative;
    img {
      max-width: 100%;
    }
    .sec4-txt {
      width: 1600px;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 50%;
        padding: 10px;
        img {
          max-width: 100%;
        }
      }
      .right {
        width: 50%;
        padding: 10px;
        .title {
          font-size: 45px;
          line-height: 1.5;
          font-weight: 600;
          margin: 0 0 20px;
        }
        .p {
          line-height: 1.5;
          font-size: 22px;
          color: #4d546f;
        }
      }
    }
  }
  .sec5 {
    position: relative;
    display: flex;
    justify-content: end;
    margin: 50px 0;
    .sec5-txt {
      width: 1600px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 50%;
        padding: 10px;
        img {
          max-width: 100%;
        }
      }
      .right {
        width: 50%;
        padding: 10px;
        .title {
          font-size: 45px;
          line-height: 1.5;
          font-weight: 600;
          margin: 0 0 20px;
        }
        .p {
          line-height: 1.5;
          font-size: 22px;
          color: #4d546f;
          margin: 20px 0;
          display: flex;
          align-items: center;
          .p-icon {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .sec6 {
    margin: 50px 0;
    text-align: center;
    letter-spacing: 1.5px;
    padding: 50px 0;
    position: relative;
    .title {
      font-size: 60px;
      font-weight: 500;
      margin: 0 0 50px;
    }
    .little-title {
      line-height: 2;
      font-size: 24px;
      margin: 0 0 20px;
      color: #4d546f;
    }
    .row {
      width: 1400px;
      margin: 50px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .col {
        width: 30%;
        padding: 50px;
        display: flex;
        flex-direction: column;
        height: 550px;
        justify-content: space-between;
        box-shadow: 0px 35px 46px 0px rgba(172, 189, 199, 0.28);
        .tl1 {
          font-size: 50px;
          font-weight: 600;
        }
        .tl2 {
          font-size: 20px;
          font-weight: 700;
          span {
            font-size: 85px;
          }
        }
        .tl3 {
          text-align: left;
          line-height: 1.5;
          font-size: 24px;
        }
        .tl4 {
          font-size: 40px;
          font-weight: 700;
          &:hover {
            color: #008dec;
            cursor: pointer;
          }
        }
        &:nth-child(1) {
          background-image: linear-gradient(59deg, #d5fc71 0%, #64feab 95%);
        }
        &:nth-child(2) {
          background-image: linear-gradient(59deg, #06ffdf 0%, #42dbef 95%);
        }
        &:nth-child(3) {
          background-image: linear-gradient(59deg, #0bfce0 0%, #c5fd78 95%);
        }
      }
    }
  }
  .sec7 {
    margin: 50px 0;
    text-align: center;
    letter-spacing: 1.5px;
    padding: 50px 0;
    position: relative;
    .title {
      font-size: 60px;
      font-weight: 500;
      margin: 0 0 50px;
    }
    .little-title {
      line-height: 2;
      font-size: 24px;
      margin: 0 0 20px;
      color: #4d546f;
    }
    .input-row {
      width: 1000px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 20px;
      height: 80px;
      background: #fff;
      box-shadow: 0px 35px 46px 0px rgba(172, 189, 199, 0.28);
      .input {
        width: calc(100% - 150px);
        padding: 0 20px;
        display: flex;
        align-items: center;
        color: #4d546f;
      }
      .btn {
        width: 180px;
        background-color: #8986ff;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      }
    }
  }
  img {
    position: relative;
    z-index: -1;
  }
  #feature {
    margin: 0 0 150px;
  }
  #pricing {
    margin: 0 0 150px;
  }
}

.r1 {
  position: absolute;
  top: 20%;
  right: 20%;
  animation: 2s infinite move ease-in alternate;
}
.r2 {
  position: absolute;
  top: 22%;
  right: 43%;
  animation: 2s infinite down ease-in alternate;
}
.r3 {
  position: absolute;
  top: 15%;
  left: 5%;
  animation: 2s infinite down ease-in alternate;
}
.r4 {
  position: absolute;
  bottom: 20%;
  left: 10%;
  animation: 2s infinite move ease-in alternate;
}
.r5 {
  position: absolute;
  bottom: 30%;
  left: 60%;
  animation: 2s infinite down ease-in alternate;
}
.r6 {
  position: absolute;
  bottom: 48%;
  left: 20%;
  animation: 2s infinite down ease-in alternate;
}
.r7 {
  position: absolute;
  bottom: 47%;
  left: 88%;
  animation: 2s infinite move ease-in alternate;
}
.r8 {
  position: absolute;
  bottom: 32%;
  right: 60%;
  animation: 2s infinite down ease-in alternate;
}
.r9 {
  position: absolute;
  top: 55%;
  right: 19%;
  animation: 2s infinite down ease-in alternate;
}

@keyframes down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(50%);
  }
}

@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50%);
  }
}

@media screen and (max-width: 1600px) {
  .story-page {
    .sec1 {
      .sec1-txt {
        .title {
          font-size: 40px;
        }
        .p {
          font-size: 20px;
        }
      }
    }
    .sec2 {
      margin: -100px auto 0;
    }
    .sec3 {
      .title {
        font-size: 40px;
        margin: 0 0 20px;
      }
      .little-title {
        font-size: 20px;
      }
      .row {
        width: 100%;
        padding: 0 10px;
        .col {
          .txt {
            font-size: 16px;
          }
        }
      }
    }
    .sec4 {
      .sec4-txt {
        width: 100%;
        padding: 0 20px;
        .right {
          .title {
            font-size: 30px;
          }
          .p {
            font-size: 16px;
          }
        }
      }
    }
    .sec5 {
      .sec5-txt {
        width: 100%;
        padding: 0 20px;
        .right {
          .title {
            font-size: 30px;
          }
          .p {
            font-size: 16px;
          }
        }
      }
    }
    .sec6 {
      padding: 0 10px;
      .title {
        font-size: 40px;
        margin: 0 0 20px;
      }
      .little-title {
        font-size: 16px;
      }
      .row {
        width: 100%;
        padding: 0 20px;
        .col {
          height: 380px;
          .tl1 {
            font-size: 20px;
          }
          .tl2 {
            span {
              font-size: 40px;
            }
          }
          .tl3 {
            font-size: 16px;
          }
          .tl4 {
            font-size: 20px;
          }
        }
      }
    }
    .sec7 {
      .title {
        font-size: 40px;
        margin: 0 0 20px;
      }
      .little-title {
        font-size: 16px;
      }
      .input-row {
        height: 60px;
        width: 90%;
        margin: auto;
        .btn {
          width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .story-page {
    .sec1 {
      .sec1-txt {
        padding: 0 10px;
        .title {
          font-size: 30px;
        }
        .p {
          font-size: 16px;
        }
      }
    }
    .sec2 {
      margin: auto;
      img {
        width: 100%;
      }
    }
    .sec3 {
      padding: 0 10px;
      .title {
        font-size: 28px;
      }
      .row {
        flex-wrap: wrap;
        .col {
          margin: 10px;
          width: 100%;
          .txt {
            font-size: 16px;
          }
        }
      }
    }
    .sec4 {
      .sec4-txt {
        flex-wrap: wrap;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
        }
      }
    }
    .sec5 {
      .sec5-txt {
        flex-wrap: wrap;
        .left {
          width: 100%;
        }
        .right {
          width: 100%;
        }
      }
    }
    .sec6 {
      .row {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        .col {
          width: 90%;
          margin: 10px 0;
        }
      }
    }
    .sec7 {
      .input-row {
        font-size: 15px;
      }
    }
  }
  .r1 {
    display: none;
  }
  .r2 {
    display: none;
  }
  .r3 {
    display: none;
  }
  .r4 {
    display: none;
  }
  .r5 {
    display: none;
  }
  .r6 {
    display: none;
  }
  .r7 {
    display: none;
  }
  .r8 {
    display: none;
  }
  .r9 {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .story-page {
    .sec1 {
      height: 500px;
      overflow: hidden;
      img {
        width: auto;
        height: auto;
      }
      .sec1-txt {
        width: 99%;
        .title {
          font-size: 20px;
          margin: 0 0 10px;
          line-height: 1.2;
        }
        .p {
          margin: 10px 0 0;
          font-size: 14px;
        }
      }
    }
    .sec2 {
      display: none;
    }
    .sec6 {
      .row {
        justify-content: center;
        align-items: center;
        .col {
          width: 300px;
        }
      }
    }
  }
}
