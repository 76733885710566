.contact-page {
  width: 100%;
  padding: 100px 0 0;
  .title {
    font-size: 40px;
    text-align: center;
    margin: 30px 0 50px;
    font-weight: 580;
  }
  .form {
    width: 1200px;
    margin: 50px auto;
    background-color: #f3f7fb;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    .col {
      width: 50%;
      padding: 10px;
      input {
        width: 100%;
        padding: 15px;
        margin: 0 0 15px;
        background: #f7fafc;
        border: 1px solid #ced4da;
        border-radius: 5px;
        &:focus {
          outline: none;
        }
      }
      textarea {
        max-width: 100%;
        min-width: 100%;
        padding: 15px;
        margin: 15px 0;
        min-height: 200px;
        background: #f7fafc;
        border: 1px solid #ced4da;
        border-radius: 5px;
        &:focus {
          outline: none;
        }
      }
      p {
        margin: 0 0 30px;
        line-height: 1.5;
        font-size: 20px;
        color: #4d546f;
      }
      .btn {
        width: 200px;
        padding: 15px;
        font-size: 18px;
        text-align: center;
        border-radius: 5px;
        background-image: linear-gradient(6deg, #17ffd3 0%, #23e3ee 100%);
        &:hover {
          background-image: linear-gradient(186deg, #17ffd3 0%, #23e3ee 100%);
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .contact-page {
    padding: 50px 0 0;
    .title {
      font-size: 30px;
    }
    .form {
      width: 100%;
    }
  }
}
@media screen and (max-width: 800px) {
  .contact-page {
    .form {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .col {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .contact-page {
    .title{
        font-size: 24px;
    }
    .form {
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 30px;
      .col {
        width: 100%;
        padding: 0;
        margin: 0 0 20px;
      }
    }
  }
}
