$bgcolor: linear-gradient(147deg, #8c54e9 0%, #4b76e7 74%);

.login-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: #000;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(#000, #0f0, #000);
    animation: animate 5s linear infinite;
  }
  span {
    position: relative;
    display: block;
    width: calc(6.25vw - 2px);
    height: calc(6.25vw - 2px);
    background: #181818;
    z-index: 2;
    transition: 1.5s;
    &:hover {
      background: #0f0;
      transition: 0s;
    }
  }
  .login-box {
    position: absolute;
    width: 500px;
    background: #222;
    z-index: 100;
    padding: 40px 60px;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(0, 0, 0, 9);
    h1 {
      font-size: 32px;
      position: relative;
      color: #0f0;
      text-align: center;
    }
    .input-row {
      margin: 30px 0;
      input {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: block;
        padding: 15px 15px;
        padding-left: 25px;
        margin: 26px 0;
        border: none;
        border-radius: 4px;
        font-weight: 500;
        background-color: #333;
        color: #fff;
        &:focus {
          outline: none;
          color: #fff;
        }
      }
    }
    .code-row {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 50%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: block;
        padding: 15px 15px;
        padding-left: 25px;
        margin: 26px 0;
        border: none;
        background-color: #333;
        border-radius: 4px;
        font-weight: 500;
        color: #fff;
        &:focus {
          outline: none;
          color: #fff;
        }
      }
      .btn {
        width: 40%;
        padding: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0f0;
        font-weight: 500;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
    .btn-row {
      margin: 30px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        width: 100%;
        padding: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0f0;
        font-weight: 500;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

@media screen and (max-width: 500px) {
  .login-page {
    .login-box {
      width: 90%;
      padding: 20px;
    }
  }
}
