.success-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;

  .gif {
    width: 100px;
    height: 100px;
    // background-color: #fff;
    // border-radius: 50px;
    // overflow: hidden;
  }
}
