// leftbar
$title-color: #fff;
$leftBar-bgcolor: rgb(35, 35, 63);
$leftBar-fontColor: rgb(115, 123, 139);
$bgcolor: rgb(118, 137, 231);
$background: rgb(84, 105, 212);
$hover-background: rgba(255, 255, 255, 0.35);
$icon-background: linear-gradient(
  to right top,
  rgb(118, 137, 231),
  rgb(77, 105, 245)
);
$icon-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
  rgba(77, 105, 245, 0.2) 0px 10px 15px -3px,
  rgba(77, 105, 245, 0.2) 0px 4px 6px -4px;

$charge-header:linear-gradient(
  247.95deg,
  #ededed 5.87%,
  #f6f6f6 96.55%
);
