.headerBar {
  width: 100%;
  position: fixed;
  padding: 30px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  letter-spacing: 1.5px;
  transition: all 0.3s;
  .logo {
    font-size: 25px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
  .ul {
    display: flex;
    align-items: center;
    li {
      padding: 0 20px;
      margin: 0 10px;
      font-size: 20px;
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        font-size: 22px;
      }
    }
  }
  .try-free {
    background: linear-gradient(205deg, #17ffd3 0%, #d3fc71 95%);
    color: #091337;
    font-size: 20px;
    padding: 15px 60px;
    border-radius: 5px;
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      transform: scale(1.15);
      background: linear-gradient(205deg, #d3fc71 0%, #17ffd3 95%);
    }
  }
  .mobile-btn {
    display: none;
  }
}

.menu-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.105);
  .menu-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 430px;
    height: 100%;
    background-color: #fff;
    .head {
      text-align: center;
      padding: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.105);
      font-size: 22px;
      font-weight: 500;
      position: relative;
      .close {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        &:hover {
          cursor: pointer;
        }
      }
    }
    .ul {
      li {
        padding: 15px 0;
        font-size: 20px;
        text-align: center;
      }
    }
    .try-free {
      width: 200px;
      margin: 20px auto;
      background: linear-gradient(205deg, #17ffd3 0%, #d3fc71 95%);
      color: #091337;
      font-size: 20px;
      padding: 15px 60px;
      border-radius: 5px;
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        transform: scale(1.15);
        background: linear-gradient(205deg, #d3fc71 0%, #17ffd3 95%);
      }
    }
  }
}

.scroll {
  background-color: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 141, 236, 0.1);
}

@media screen and (max-width: 1400px) {
  .headerBar {
    padding: 15px 15px;
    .ul {
      display: none;
    }
    .try-free {
      display: none;
    }
    .mobile-btn {
      display: block;
    }
  }
}

@media screen and (max-width: 430px) {
  .menu-mask {
    .menu-content {
      width: 100%;
    }
  }
}
