.pop-box {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 350px;
  padding: 15px 20px;
  background-color: rgba(68, 113, 209, 0.8);
  animation-delay: calc(-1s * var(--i));
  border-bottom: 0.0625rem solid rgba(243, 241, 241, 0.4);
  z-index: 300;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0.3125rem 0.3125rem 0 rgba(68, 113, 209, 0.4);
  color: #fff;
  font-weight: 500;
  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: #fff;
    border-radius: 25px;
  }
}

@keyframes animate1 {
  0% {
    transform: translateY(-6.25rem);
  }
  50% {
    transform: translateY(65px);
  }
  75% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(65px);
  }
}

// @media screen and (max-width: 700px) {
//   .pop-box {
//     left: 20%;
//   }
// }
