@import "./common/common.scss";
.comment-toolbar {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 0 10px;
  .logo {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: $icon-shadow;
    background: $icon-background;
    &:hover {
      cursor: pointer;
    }
  }
}
.comment-page {
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid rgb(238, 238, 238);
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  overflow: hidden;
  padding: 20px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    font-size: 16px;
    font-weight: 700;
    color: rgb(66, 66, 66);
    border-bottom: 1px solid #e8edf2;
    text-align: center;
    .left {
      display: flex;
      align-items: center;
      .title {
        width: 200px;
      }
      .icon {
        width: 100px;
      }
      .nickname {
        width: 150px;
      }

      .description {
        width: 300px;
      }
      .state {
        width: 100px;
      }
      .time {
        width: 200px;
      }
    }
    .tool {
      width: 150px;
      text-align: center;
    }
  }
  .body {
    .li {
      height: 60px;
      border-top: 1px solid rgba(0, 0, 0, 0.105);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        height: 100%;
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #697a8d;
        text-align: center;
        .title {
          width: 200px;
          padding: 0 5px;
          word-wrap: break-word;
        }
        .nickname {
          width: 150px;
          padding: 0 5px;
          word-wrap: break-word;
        }
        .icon {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 20px;
            max-width: 100%;
          }
        }
        .description {
          width: 300px;
          overflow-y: scroll;
          height: 100%;
          display: flex;
          justify-content: center;
          padding: 5px;
          p {
            img {
              max-width: 100%;
            }
          }
        }
        .state {
          width: 100px;
        }
        .on {
          color: #53e60f;
        }
        .off {
          color: #e60f0f;
        }
        .time {
          width: 200px;
        }
      }
      .tool {
        width: 150px;
        padding: 0 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    .mobile-li {
      color: #697a8d;
      font-size: 14px;
      display: none;
      margin: 10px 0 0;
      overflow: hidden;
      .row {
        display: flex;
        padding: 10px 0;
        align-items: center;
        position: relative;
        .left {
          margin-right: 10px;
          padding: 10px;
          .img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50px;
            img {
              max-width: 100%;
            }
          }
        }
        .right {
          .r-row {
            display: flex;
            align-items: center;
            margin: 7px 0;
            font-weight: 550;
            font-size: 14px;
            i {
              margin: 0 10px;
            }
            .value {
              padding: 0 5px;
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              /* 以下样式实现多行省略 */
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;

              /* 限制为两行 */
              span {
                color: #e60f0f;
              }
              .on {
                color: #53e60f;
              }
              .off {
                color: #e60f0f;
              }
              .sku {
                display: flex;
                align-items: center;
                img {
                  height: 15px;
                  margin-right: 5px;
                }
              }
            }
            .type {
              color: #e6700f;
            }
            .state {
              width: 100px;
              padding: 0 5px;
              .state-btn {
                padding: 8px;
                border-radius: 50px;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                color: #fff;
              }
            }
          }
        }

        .btn {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
    }

    .no-content {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 500px;
    }
  }
}
.footer {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiPaginationItem-page.Mui-selected {
    background: rgba(70, 120, 230);
    border-color: rgba(70, 120, 230);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(25, 29, 253, 0.4);
    color: #fff;
  }
}

.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) {
  .comment-page {
    overflow-x: scroll;
    .head {
      font-size: 14px;
      .left {
        .icon {
          width: 100px;
        }
        .nickname {
          width: 100px;
        }
        .description {
          width: 200px;
        }
        .time {
          width: 120px;
        }
      }
    }
    .body {
      .li {
        font-size: 14px;
        .left {
          .icon {
            width: 100px;
          }
          .nickname {
            width: 100px;
          }
          .description {
            width: 200px;
          }
          .time {
            width: 120px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .comment-page {
    padding: 0;
    .head {
      display: none;
    }
    .body {
      .li {
        display: none;
      }
      .mobile-li {
        display: block;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.105);
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }
}
