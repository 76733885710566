.alertMax-box {
  position: fixed;
  width: 400px;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  background-color: blanchedalmond;
  border: 1px solid red;
  z-index: 100;
  padding: 20px 40px;
  line-height: 1.2;
  .tip-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
  .close-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    transition: all 0.2s;
    &:hover {
      cursor: pointer;
    }
  }
  .tip-content {
    display: flex;
    align-items: center;
  }
}

.warning {
  border: 1px solid #720202 !important;
  background-color: #ffefef !important;
  color: #720202 !important;
}

.tip {
  background-color: #f0ffef;
  border: 1px solid #027243;
  color: #027243;
}
