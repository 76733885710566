@import "./common/common.scss";
.product-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: #ffffff;
  padding: 50px;
  .row {
    width: 1000px;
    margin: 10px 0;
    .title {
      font-size: 20px;
      margin: 0 0 20px;
      font-weight: 600;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      .li {
        font-size: 16px;
        width: 30%;
        padding: 20px;
        background-color: #fff;
        border: 1px solid rgb(238, 238, 238);
        box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
        margin: 15px 15px 15px 0;
        border-radius: 10px;
        overflow: hidden;
        &:hover {
          cursor: pointer;
          border: 1px solid #a9aef7;
        }
        .li-title {
          color: #3a5072;
          display: flex;
          align-items: center;
          font-weight: 600;
          text-align: center;
          margin: 0;
          .key {
            margin-right: 20px;
            display: flex;
            align-items: center;
            .img {
              height: 30px;
              width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                max-width: 100%;
              }
            }
            p {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .checkout {
    padding: 10px;
    background-color: #5bcfc5;
    box-shadow: 1px 3px 20px 0px #5bcfc5;
    width: 120px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
}

.product-active {
  border: 2px solid #5864ff !important;
}

.checkout-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  .checkout-box {
    width: 600px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 5px 20px 10px rgba(0, 0, 0, 0.205);
    overflow: hidden;
    letter-spacing: 1.5px;
    position: relative;
    height: 100%;
    .close {
      position: absolute;
      top: 25px;
      right: 20px;
    }
    .header {
      padding: 30px 50px;
      background: $icon-background;
      color: #fff;
      font-weight: 600;
      text-align: center;
    }
    .checkout-content {
      width: 100%;
      padding: 30px 50px;
      overflow-y: scroll;
      height: calc(100% - 80px);
      .row {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 0 0 20px;
        font-size: 16px;
        .title {
          width: 200px;
        }
        .value {
          width: calc(100% - 200px);
          display: flex;
          align-items: center;
          justify-content: end;
          .sku-img {
            height: 15px;
            margin-right: 10px;
          }
        }
      }
      .total {
        font-weight: 600;
      }
      .enter-model {
        margin: 0 0 20px;
        border-radius: 5px;
        padding: 10px;
        background-color: rgba(218, 221, 224, 0.2);
        .enter-row {
          margin: 15px 0;
          .title {
            margin: 10px 0;
          }
          .react-tel-input {
            width: 100%;
            height: 40px;
            box-shadow: 0;
            input {
              width: 100%;
              height: 42px;
              border: 0;
              font-size: 14px;
              overflow: hidden;
              background-color: #fff;
              box-shadow: none;
              border: 1px solid #d9d9d9;
              padding-left: 60px;
              &::placeholder {
                font-size: 14px;
              }
              &:focus {
                outline: none;
              }
            }
            .selected-flag {
              border: 0;
              background-color: transparent;
              left: 9px;
              transform: scale(1.5, 1.5);
              top: 5px;
            }
          }
          input {
            width: 100%;
            border: 1px solid rgb(218, 221, 224);
            padding: 10px;
            &:focus {
              border: 2px solid rgb(69, 103, 216);
              outline: none;
            }
          }
          .err {
            border: 1px solid red;
          }
          textarea {
            width: 100%;
            border: 1px solid rgb(218, 221, 224);
            padding: 10px;
            &:focus {
              border: 2px solid rgb(69, 103, 216);
              outline: none;
            }
          }
          span {
            color: red;
            font-size: 12px;
            margin: 5px 0;
          }
        }
      }
      .footer-txt {
        margin: 30px 0;
        color: rgb(156, 156, 156);
        .txt-row {
          padding: 15px 0;
          border-top: 1px solid rgb(218, 221, 224);
          .title {
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .txt-content {
            margin: 15px 0 0;
            img {
              max-width: 100%;
            }
          }
        }
      }

      .btn-row {
        display: flex;
        justify-content: center;
        align-items: center;
        .checkout-btn {
          width: 120px;
          margin: 20px auto;
          padding: 10px;
          background-color: #5bcfc5;
          box-shadow: 1px 3px 20px 0px #5bcfc5;
          text-align: center;
          border-radius: 5px;
          color: #fff;
          font-weight: 600;
          &:hover {
            cursor: pointer;
            opacity: 0.85;
          }
        }
      }
    }
  }
}

.checkout-show {
  display: flex !important;
}

@media screen and (max-width: 1200px) {
  .product-page {
    overflow-x: scroll;
    padding: 20px;
    .row {
      width: 100%;
      .title {
        font-size: 18px;
      }
      .content {
        .li {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .product-page {
    padding: 20px;
    .row {
      width: 100%;
      .content {
        .li {
          width: 46%;
        }
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .product-page {
    padding: 20px;
    .row {
      width: 100%;
      .content {
        .li {
          width: 100%;
        }
      }
    }
  }
  .checkout-mask {
    .checkout-box {
      width: 98%;
      .checkout-content {
        padding: 40px 20px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .checkout-mask {
    .checkout-box {
      .checkout-content {
        padding: 40px 12px;
        .row {
          font-size: 12px;
          .title {
            width: 150px;
          }
          .value {
            width: calc(100% - 150px);
            flex-wrap: wrap;
            justify-content: end;
          }
        }
        .enter-model {
          .enter-row {
            font-size: 12px;
          }
        }
      }
    }
  }
  .product-page {
    padding: 10px;
    .row {
      .content {
        .li {
          margin: 15px 0;
          .li-title {
            font-size: 14px;
            .key {
              .img {
                height: 20px;
                width: 30px;
              }
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
