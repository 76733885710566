.map-cricle {
  width: 10px;
  height: 10px;
  background-color: #5864ff;
  // box-shadow: 0 2px 4px 0 rgba(34, 74, 250, 0.4);
  box-shadow: 0 0 0 0 rgba(100, 96, 244, 0.105);
  animation: pulse1 1.5s infinite;
  border-radius: 50%;
}

.add {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5864ff;
  &:hover {
    cursor: pointer;
  }
}
.sub {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5864ff;
  &:hover {
    cursor: pointer;
  }
}

@keyframes pulse1 {
  0% {
    transform: scale(0.6);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(100, 96, 244, 0.7);
  }
  100% {
    transform: scale(0.6);
    box-shadow: 0 0 0 0 rgba(100, 96, 244, 0);
  }
}
