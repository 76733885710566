@import "./common/common.scss";

.dashboard-page {
  width: 100%;
  height: 100%;
  padding: 15px;
  position: relative;
  .card-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0 20px;
    .card {
      width: 24%;
      height: 150px;
      border-radius: 10px;
      padding: 30px;
      position: relative;
      overflow: hidden;
      background-color: #fff;
      border: 1px solid rgb(238, 238, 238);
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      position: relative;
      letter-spacing: 1.2px;
      .num {
        display: flex;
        align-items: center;
        p {
          font-size: 30px;
          margin: 0 10px;
          font-weight: 500;
        }
      }
      .title {
        margin: 15px 0 0;
        font-size: 13px;
        font-weight: normal;
      }
      .btn {
        width: 100px;
        position: absolute;
        overflow: hidden;
        padding: 10px 0;
        text-align: center;
        bottom: 20px;
        right: 20px;
        border-radius: 5px;
        background-color: #5864ff;
        border: 2px solid #5864ff;
        color: #fff;
        font-weight: 600;
        box-shadow: 3px 4px 0px 0px #354783;
        transition: all 0.3s;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
        &:active {
          box-shadow: none;
        }
      }
      .btn2 {
        right: 130px;
        color: #5864ff;
        background-color: #fff;
        border: 2px solid #5864ff;
        box-shadow: 3px 4px 0px 0px #5864ff;
      }
    }
  }
  .datePicker {
    width: 24%;
    height: 150px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
    background-color: #fff;
    .semi-datepicker-range-input {
      background-color: #fff;
      height: 100%;
      &:hover {
        background-color: #fff;
      }
    }
    .semi-datepicker-range-input {
      background-color: #fff;
    }
    input {
      font-size: 15px;
      &::placeholder {
        color: #6c6e6e;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .chart-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;

    .col {
      width: 49%;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      padding: 20px;
      border: 1px solid rgb(238, 238, 238);
      .title {
        margin: 20px 0;
        display: flex;
        align-items: center;
        letter-spacing: 2px;
        .title-p {
          line-height: 1.2;
          font-weight: 500;
          span {
            color: #969696;
            font-weight: normal;
            font-size: 15px;
          }
        }
      }
      .chart-modle {
        height: 520px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .second-row {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    .col {
      width: 49%;
      height: 600px;
      background-color: #fff;
      border: 1px solid rgb(238, 238, 238);
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      padding: 25px 25px 50px;
      overflow: hidden;
      border-radius: 20px;
      position: relative;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        letter-spacing: 2px;
        .left {
          display: flex;
          align-items: center;
        }
      }
      .content {
        margin: 20px 0 0;
        height: calc(100% - 50px);
        overflow-y: scroll;
        padding: 10px 0;
        position: relative;
        .ant-timeline-item {
          font-size: 15px;
          letter-spacing: 1.5px;
          color: #424242;
        }

        .li {
          margin: 10px 0;
          padding: 10px 0;
          border-bottom: 1px solid rgba(167, 167, 167, 0.15);
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          font-weight: 550;
          color: #424242;
          letter-spacing: 1.5px;
          &:last-child() {
            border-bottom: 0;
          }
          .left {
            display: flex;
            align-items: center;
            position: relative;
            .img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
              padding: 10px;
              background-color: rgb(249, 250, 251);
              border: 1px solid rgba(167, 167, 167, 0.105);
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
              }
            }
            .flag {
              margin-right: 15px;
            }
            .name {
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              color: #fff;
              font-size: 13px;
              padding: 5px;
              border-radius: 5px;
              letter-spacing: normal;
              margin-left: 5px;
            }
            .private {
              background-color: rgb(235, 94, 40);
            }
            .personal {
              background-color: rgb(243, 187, 69);
            }
            .public {
              background-color: rgb(122, 194, 154);
            }
          }
          .value {
            color: #5864ff;
            font-weight: 700;
            font-size: 18px;
            span {
              color: #424242;
              font-size: 13px;
              text-decoration: line-through;
            }
          }
          .sku {
            display: flex;
            justify-content: start;
            .img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
              padding: 10px;
              background-color: rgb(249, 250, 251);
              border: 1px solid rgba(167, 167, 167, 0.105);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
              }
            }
          }
          .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .operation {
              display: flex;
              width: 60px;
              margin-left: 50px;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .country-row {
    background-color: #fff;
    height: 500px;
    margin: 20px 0;
  }
  .third-row {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    .col {
      width: 49%;
      height: 600px;
      background-color: #fff;
      border: 1px solid rgb(238, 238, 238);
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      padding: 25px 25px 50px;
      overflow: hidden;
      border-radius: 20px;
      position: relative;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        letter-spacing: 2px;
        .left {
          display: flex;
          align-items: center;
        }
      }
      .content {
        margin: 20px 0 0;
        height: calc(100% - 50px);
        overflow-y: scroll;
        padding: 10px 0;
        position: relative;
        .ant-timeline-item {
          font-size: 15px;
          letter-spacing: 1.5px;
          color: #424242;
        }

        .li {
          margin: 10px 0;
          padding: 10px 0;
          border-bottom: 1px solid rgba(167, 167, 167, 0.15);
          display: flex;
          justify-content: space-between;
          align-items: center;
          overflow: hidden;
          font-weight: 550;
          color: #424242;
          letter-spacing: 1.5px;
          &:last-child() {
            border-bottom: 0;
          }
          .left {
            display: flex;
            align-items: center;
            position: relative;
            .img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 10px;
              padding: 10px;
              background-color: rgb(249, 250, 251);
              border: 1px solid rgba(167, 167, 167, 0.105);
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
              }
            }
            .flag {
              margin-right: 30px;
            }
            .name {
              max-width: 250px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              color: #fff;
              font-size: 13px;
              padding: 5px;
              border-radius: 5px;
              letter-spacing: normal;
              margin-left: 5px;
            }
            .private {
              background-color: rgb(235, 94, 40);
            }
            .personal {
              background-color: rgb(243, 187, 69);
            }
            .public {
              background-color: rgb(122, 194, 154);
            }
          }
          .value {
            color: #5864ff;
            font-weight: 700;
            font-size: 18px;
            span {
              color: #424242;
              font-size: 13px;
              text-decoration: line-through;
            }
          }
          .sku {
            display: flex;
            justify-content: start;
            .img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
              padding: 10px;
              background-color: rgb(249, 250, 251);
              border: 1px solid rgba(167, 167, 167, 0.105);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
              }
            }
          }
          .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .operation {
              display: flex;
              width: 60px;
              margin-left: 50px;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
    .country {
      width: 24%;
    }
    .map {
      width: 74.6%;
      display: flex;
      justify-content: center;
      align-items: start;
      overflow: hidden;
      padding: 0;
      position: relative;
      .ComposableMap {
        width: 100%; /* 设置适合你的地图尺寸 */
        height: 100%;
        overflow: hidden; /* 避免外部滚动条干扰 */
        position: relative;
      }
      .icon-header {
        position: absolute;
        top: 25px;
        left: 25px;
        display: flex;
        align-items: center;
        font-weight: 500;
        letter-spacing: 2px;
        z-index: 3;
      }
    }
  }
  .footer {
    margin: 20px 0 0;
    padding: 20px;
    background-color: $leftBar-bgcolor;
    color: #fff;
  }
}

.icon {
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}

.title-logo {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: $icon-shadow;
  background: $icon-background;
}

.view {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #5864ff;
  text-align: center;
  font-weight: 600;
  background-color: #fff;
  padding: 15px 0;
  &:hover {
    cursor: pointer;
    color: #424242;
  }
}

.charge-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  .charge-box {
    width: 600px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 5px 20px 10px rgba(0, 0, 0, 0.205);
    overflow: hidden;
    letter-spacing: 1.5px;
    position: relative;
    .close {
      position: absolute;
      top: 25px;
      right: 20px;
    }
    .header {
      padding: 30px 50px;
      background: $icon-background;
      color: #fff;
      font-weight: 600;
      text-align: center;
    }
    .charge-content {
      padding: 30px;
      .btn-model {
        min-height: 200px;
        display: flex;
        align-items: center;
      }
      .charge-model {
        .wallet-card {
          .row {
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0 0 20px;
            .wallet-img {
              height: 100px;
              width: 100px;
              margin-right: 10px;
              img {
                width: 100%;
              }
            }
            .txt {
              width: calc(100% - 110px);
              color: #424242;
              .p {
                margin: 0 0 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
              }
            }
            .btn {
              width: 100%;
              text-align: center;
              padding: 10px;
              background: #c9c9c9;
              border-radius: 5px;
              transition: all 0.5s;
              &:hover {
                cursor: pointer;
                background: $charge-header;
              }
            }
          }
          .flex-no {
            display: block;
            .input2 {
              width: 100%;
              border: 1px solid rgb(218, 221, 224);
              padding: 15px;
              border-radius: 10px;
              font-size: 18px;
              font-weight: 600;
              color: #697a8d;
              &:focus {
                border: 2px solid rgb(69, 103, 216);
                outline: none;
              }
            }
          }
        }
      }

      .row {
        width: 100%;
        margin: 20px 0;
        .title {
          margin: 0 0 20px;
          font-weight: 600;
        }
        .input {
          position: relative;
          .usd {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            padding: 15px;
            color: #697a8d;
            border-right: 1px solid rgb(218, 221, 224);
          }
          input {
            width: 100%;
            border: 1px solid rgb(218, 221, 224);
            padding: 15px 15px 15px 80px;
            border-radius: 10px;
            font-size: 18px;
            font-weight: 600;
            color: #697a8d;
            &:focus {
              border: 2px solid rgb(69, 103, 216);
              outline: none;
            }
          }
        }
        .input2 {
          width: 100%;
          border: 1px solid rgb(218, 221, 224);
          padding: 15px;
          border-radius: 10px;
          font-size: 18px;
          font-weight: 600;
          color: #697a8d;
          &:focus {
            border: 2px solid rgb(69, 103, 216);
            outline: none;
          }
        }

        .file {
          position: relative;
          padding: 30px;
          color: #6c6e6e;
          border: 3px dashed rgb(232, 237, 242);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .file-input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            &:hover {
              cursor: pointer;
            }
          }
          .p {
            margin: 10px 0 0;
            letter-spacing: 2px;
            line-height: 1.5;
            font-weight: 600;
          }
          .img {
            height: 50px;
            img {
              height: 100%;
            }
          }
        }
        .control {
          font-size: 16px;
          font-weight: 600;
          color: #697a8d;
          width: 100%;
          height: 50px;
        }
        .slider {
          margin: 20px 0;
          .MuiSlider-root {
            .MuiSlider-rail {
              border-radius: 0.1875rem !important;
              background: #d2d6de !important;
            }
            .MuiSlider-thumb {
              width: 0.9375rem !important;
              height: 0.9375rem !important;
              background-color: #5bcfc5 !important;
              margin-top: -0.4375rem !important;
            }
            .MuiSlider-track {
              background-color: #5bcfc5 !important;
            }
          }
        }
      }
      .btn-row {
        display: flex;
        justify-content: center;
        align-items: center;
        .charge-btn {
          width: 100%;
          margin: 20px auto;
          padding: 15px;
          background-color: #546fc6;
          box-shadow: 1px 3px 20px 0px #546fc6;
          text-align: center;
          border-radius: 5px;
          color: #fff;
          font-weight: 600;
          &:hover {
            cursor: pointer;
            opacity: 0.85;
          }
        }
      }
    }
  }
}

.charge-show {
  display: flex !important;
}

@media screen and (max-width: 1800px) {
  .dashboard-page {
    .card-row {
      flex-wrap: wrap;
      .card {
        width: 48%;
        margin: 20px 0;
      }
      .datePicker {
        width: 48%;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .dashboard-page {
    .card-row {
      flex-wrap: wrap;
      .card {
        padding: 20px;
        .num {
          p {
            margin: 0;
            font-size: 24px;
          }
        }
        .btn {
          right: 20px;
          bottom: 20px;
        }
        .btn2 {
          right: 130px;
        }
      }
    }
    .chart-row {
      flex-wrap: wrap;
      .col {
        width: 100%;
        margin: 0 0 20px;
      }
    }
    .second-row {
      flex-wrap: wrap;
      .col {
        width: 100%;
        margin: 0 0 20px;
      }
    }
    .third-row {
      flex-wrap: wrap;
      .col {
        width: 100%;
        margin: 0 0 20px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .dashboard-page {
    .card-row {
      flex-wrap: wrap;
      margin: 0 0 10px;
      .card {
        width: 100%;
        margin: 10px 0;
      }
      .datePicker {
        width: 100%;
        margin: 10px 0;
      }
    }
    .chart-row {
      flex-wrap: wrap;
      .col {
        width: 100%;
      }
    }
    .second-row {
      flex-wrap: wrap;
      .col {
        width: 100%;
        .content {
          .li {
            overflow-x: scroll;
          }
        }
      }
    }
    .third-row {
      .map {
        height: auto;
      }
      .col {
        margin: 0 0 10px;
      }
    }
  }
  .charge-mask {
    .charge-box {
      width: 95%;
      font-size: 14px;
      .header {
        padding: 40px 30px;
      }
      .charge-content {
        padding: 20px;
        .charge-model {
          .wallet-card {
            .row {
              margin: 0 0 10px;
              .txt {
                .p {
                  font-size: 12px;
                  word-break: break-all;
                }
              }
            }
            .flex-no {
              margin: 0 0 10px;
            }
          }
        }
      }
      .close {
        top: 15px;
        right: 15px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboard-page {
    padding: 0 !important;
    .third-row {
      .map {
        display: none;
      }
    }
  }
}
