.body-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f9;
  overflow: hidden;
  position: relative;
  .left-bar {
    width: 230px;
    height: 100%;
    transition: all 0.3s;
    padding: 0 2px;
    overflow-y: scroll;
  }
  .right-bar {
    width: calc(100% - 230px);
    height: 100%;
    transition: all 0.3s;
    header {
      width: 100%;
      height: 80px;
      background-color: #fff;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 1;
      .head-bar {
        width: 100%;
        height: 100%;
        padding: 20px 20px;
        .nav-header {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          .header-left {
            display: flex;
            align-items: center;
            h1 {
              font-size: 16px;
              color: #424242;
              font-weight: 500;
              margin: 0 10px;
              letter-spacing: 2px;
            }
            .mobile-icon {
              display: none;
            }
          }
          .header-right {
            display: flex;
            align-items: center;
            .head-li {
              margin: 0 30px;
              position: relative;
              .tip {
                width: 10px;
                height: 10px;
                background-color: #5864ff;
                // box-shadow: 0 2px 4px 0 rgba(34, 74, 250, 0.4);
                box-shadow: 0 0 0 0 rgba(100, 96, 244, 0.105);
                animation: pulse 1.5s infinite;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: -3px;
              }

              .head-icon {
                transition: all 0.3s;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.15);
                }
              }
              .order-box {
                display: none;
                position: absolute;
                top: 50px;
                right: 0;
                width: 300px;
                height: 200px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.205);
                z-index: 10;
                border: 2px solid #5864ff;
                padding: 10px;
                .square {
                  width: 20px;
                  height: 20px;
                  transform: rotate(45deg);
                  border: 2px solid #5864ff;
                  background-color: #fff;
                  position: absolute;
                  top: -12px;
                  right: 12px;
                  border-bottom: 0;
                  border-right: 0;
                }
                .notice-content {
                  width: 100%;
                  height: 100%;
                  overflow-y: scroll;
                  .notice-li {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.105);
                    font-size: 15px;
                    letter-spacing: 1.5px;
                    justify-content: space-between;
                    &:hover {
                      background-color: rgba(88, 100, 255, 0.105);
                      cursor: pointer;
                    }
                    .left {
                      display: flex;
                      align-items: center;
                      .state-btn {
                        padding: 5px;
                        border-radius: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 11px;
                        font-weight: 600;
                        color: #fff;
                      }
                      .pending {
                        background-color: #a5a5a5;
                      }
                      .processing {
                        background-color: #58bbe2;
                      }
                      .successed {
                        background-color: #54af4c;
                      }
                      .failed {
                        background-color: #f44336;
                      }
                    }
                    .li-value {
                      margin: 0 5px;
                      img {
                        max-width: 100px;
                        height: 12px;
                      }
                      span {
                        color: #5864ff;
                        font-weight: 600;
                      }
                    }
                  }
                }
                .no-content {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              .billing-box {
                display: none;
                position: absolute;
                top: 50px;
                left: -10px;
                width: 280px;
                height: 200px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.205);
                z-index: 10;
                border: 2px solid #5864ff;
                padding: 10px;
                .square {
                  width: 15px;
                  height: 15px;
                  transform: rotate(45deg);
                  border: 2px solid #5864ff;
                  background-color: #fff;
                  position: absolute;
                  top: -9px;
                  left: 12px;
                  border-bottom: 0;
                  border-right: 0;
                }
                .notice-content {
                  width: 100%;
                  height: 100%;
                  overflow-y: scroll;
                  .notice-li {
                    padding: 3px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.105);
                    font-size: 14px;
                    letter-spacing: 1.5px;
                    justify-content: space-between;
                    &:hover {
                      background-color: rgba(88, 100, 255, 0.105);
                      cursor: pointer;
                    }
                    .left {
                      display: flex;
                      align-items: center;
                      .state-btn {
                        padding: 5px;
                        border-radius: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 11px;
                        font-weight: 600;
                        color: #fff;
                      }
                      .pending {
                        background-color: #a5a5a5;
                      }
                      .processing {
                        background-color: #58bbe2;
                      }
                      .successed {
                        background-color: #54af4c;
                      }
                      .failed {
                        background-color: #f44336;
                      }
                    }
                    .li-value {
                      margin: 0 5px;
                      img {
                        max-width: 100px;
                        height: 12px;
                      }
                      span {
                        color: #5864ff;
                        font-weight: 600;
                      }
                    }
                  }
                }
                .no-content {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              .orderBox-show {
                display: block !important;
              }
              .billing-show {
                display: block !important;
              }
            }
            .profile {
              margin-left: 30px;
              border-radius: 1rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .left {
                display: flex;
                align-items: center;
                .img-box {
                  img {
                    max-width: 100%;
                    max-height: 100%;
                  }
                  p {
                    color: #424242;
                    font-size: 18px;
                    font-weight: 600;
                    padding: 0 5px;
                  }
                }

                .user-text {
                  padding: 0 0.625rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  min-width: 150px;
                  p {
                    color: #424242;
                    font-size: 20px;
                    font-weight: 600;
                  }
                  h6 {
                    font-size: 20px;
                    color: #424242;
                    font-weight: 600;
                    margin: 0 10px;
                  }
                }
              }
              .logout {
                display: flex;
                justify-content: center;
                align-items: center;
                .logout-icon {
                  transition: all 0.3s;
                }
                &:hover {
                  cursor: pointer;
                  .logout-icon {
                    transform: scale(1.15);
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-contain {
      width: 100%;
      height: calc(100% - 80px);
      padding: 10px 10px 0;
      overflow-y: scroll;
    }
  }
  .reduce-left {
    width: 66px !important;
    .leftBar {
      .left-box {
        padding: 5px !important;
        .profile {
          border-radius: 50px;
          .profile-box {
            padding: 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            background-color: transparent;
            .txt {
              display: none;
            }
          }
        }
        .head-title {
          justify-content: center;
          p {
            display: none !important;
          }
          .icon {
            transition: all 0.3s;
            &:hover {
              cursor: pointer;
              transform: scale(1.5, 1.5);
            }
          }
        }
        .tab-list {
          // padding: 0 7px !important;
          li {
            padding: 10px 0 !important;
            justify-content: center;
            border-radius: 10px;
            p {
              display: none !important;
            }
            .icon {
              transition: all 0.3s;
              &:hover {
                cursor: pointer;
                transform: scale(1.25, 1.25);
              }
            }
          }
        }
      }
    }
  }

  .reduce-right {
    width: calc(100% - 66px) !important;
  }
}

.mobile-menu {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 4;
  transition: all 0.3s;
  padding: 20px;
  .close {
    position: absolute;
    right: 20px;
    top: 40px;
  }
  .ul {
    .menu-li {
      padding: 10px 0;
      display: flex;
      align-items: end;
      letter-spacing: 2px;
      font-size: 13px;
      font-weight: 420;
      .img {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        margin-right: 10px;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.105);
        img {
          height: 100%;
        }
      }
      .menu-icon {
        transition: all 0.3s;
        margin-right: 5px;
        &:hover {
          cursor: pointer;
          transform: scale(1.15);
        }
      }
    }
  }
}

.moblie-active {
  color: #5864ff !important;
}

.menu-show {
  left: 0 !important;
}

@media screen and (max-width: 1000px) {
  .body-container {
    .reduce-left {
      padding: 5px !important;
      width: 65px !important;
      .leftBar {
        padding: 0 !important;
        .left-box {
          padding: 10px 5px !important;
          border-radius: 10px !important;
          .head-title {
            img {
              width: 35px !important;
              height: 35px !important;
            }
          }
          .tab-list {
            padding: 0 3px !important;
            .profile {
              padding: 0;
            }
            li {
              padding: 10px 0 !important;
              justify-content: center;
              height: 35px;
              p {
                display: none !important;
              }
              .icon {
                transition: all 0.3s;
                &:hover {
                  cursor: pointer;
                  transform: scale(1.05, 1.05);
                }
              }
            }
          }
        }
      }
    }
    .right-bar {
      header {
        height: 60px;
        .head-bar {
          padding-top: 20px;
        }
      }
      .page-contain {
        height: calc(100% - 60px);
      }
    }
    .reduce-right {
      width: calc(100% - 65px) !important;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(100, 96, 244, 0);
  }
  100% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(100, 96, 244, 0);
  }
}

.bounce-icon {
  animation: 1s bounce infinite;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 800px) {
  .body-container {
    .right-bar {
      header {
        .head-bar {
          padding: 20px 10px;
          .nav-header {
            .header-right {
              .head-li {
                display: none;
              }
              .profile {
                .left {
                  .user-text {
                    min-width: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .body-container {
    .left-bar {
      display: none;
    }
    .right-bar {
      header {
        .head-bar {
          .nav-header {
            .header-left {
              .head-icon {
                display: none;
              }
              .mobile-icon {
                display: block !important;
              }
              h1 {
                font-size: 14px;
              }
            }
            .header-right {
              .profile {
                .left {
                  .user-text {
                    padding: 0;
                    color: #424242;
                    p {
                      font-size: 16px;
                      font-weight: 500;
                    }
                    h6 {
                      font-size: 16px;
                      margin: 0 0 0 5px;
                      font-weight: 500;
                    }
                  }
                }
                .logout {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .reduce-right {
      width: 100% !important;
    }
  }
}
