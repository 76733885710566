@import "common.scss";

.leftBar {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .left-box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: $leftBar-bgcolor;
    box-shadow: 0px 0px 10px rgba(120, 130, 140, 0.13);
    transition: all 0.2s ease;
    padding: 10px;
    .head-title {
      height: 60px;
      display: flex;
      align-items: center;
      .img {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 2px;
        font-weight: 600;
        border: 1px solid #999;
        border-radius: 50%;
        box-shadow: $icon-shadow;
        background: $icon-background;
        color: #fff;
        img {
          width: 100%;
        }
      }
      p {
        color: $title-color;
        font-weight: 500;
        margin-left: 10px;
        letter-spacing: 2px;
      }
    }
    .tab-list {
      .profile {
        padding: 5px;
        // border: 2px dashed #fff;
        display: flex;
        align-items: center;
        border-radius: 5px;
        margin: 10px auto;
        &:hover {
          cursor: pointer;
          opacity: 0.55;
        }
        .profile-box {
          padding: 10px;
          width: 100%;
          background-color: rgba(69, 103, 216, 0.1);
          border-radius: 5px;
          display: flex;
          align-items: center;
          .img {
            width: 35px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            overflow: hidden;
            img {
              height: 100%;
            }
          }
          .txt {
            color: #fff;
            padding: 0 10px;
            font-weight: 500;
            letter-spacing: 2px;
          }
        }
      }
      li {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 23px 15px;
        margin: 10px 0;
        transition: all 0.3s;
        border-radius: 10px;

        p {
          color: $leftBar-fontColor;
          font-weight: 500;
          letter-spacing: 2px;
          margin-left: 10px;
          font-size: 14px;
        }
        &:hover {
          // background-color: $hover-background;
          cursor: pointer;
          .icon {
            transform: scale(1.15, 1.15);
          }
        }
      }
      .active {
        // background-color: $background;
        p {
          color: #fff;
        }
      }
    }
  }
}

.bounce-icon {
  animation: 1s bounce infinite;
}

@media screen and (max-width: 1200px) {
  .leftBar {
    min-height: 100%;
    // height: auto;
    .left-box {
      min-height: 100%;
      // height: auto;
    }
  }
}
@media screen and (max-height: 800px) {
  .leftBar {
    min-height: 100%;
    height: auto;
    .left-box {
      min-height: 100%;
      height: auto;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.6);
  }
  100% {
    transform: scale(1);
  }
}
