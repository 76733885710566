.about-page {
  padding: 150px 0 0;
  width: 1700px;
  margin: auto;
  letter-spacing: 1.5px;
  .title {
    font-size: 40px;
    font-weight: 600;
  }
  p {
    margin: 25px 0;
    line-height: 1.5;
    font-size: 20px;
  }
  .title1 {
    text-align: center;
    margin: 50px 0 0;
    font-size: 30px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1700px) {
  .about-page {
    width: 100%;
    padding: 70px 10px 10px;
  }
}

@media screen and (max-width: 500px) {
  .about-page {
    .title {
      font-size: 24px;
    }
  }
}
