.userLogin {
  background-color: rgb(236, 240, 243);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .btn {
    width: 180px;
    border-radius: 25px;
    margin-top: 50px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1.15px;
    background-color: #4b70e2;
    color: #f9f9f9;
    box-shadow: 8px 8px 16px #d1d9e6, -8px -8px 16px #f9f9f9;
    padding: 20px;
    text-align: center;
    transition: all 0.3s;
    &:hover {
      opacity: 0.85;
      cursor: pointer;
      transform: scale(1.05, 1);
    }
  }

  .contain {
    width: 1000px;
    height: 600px;
    padding: 25px;
    background-color: #ecf0f3;
    box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .txt-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      transform: translateX(-10%);
      height: 100%;
      width: 40%;
      padding: 50px;
      z-index: 15;
      transition: all 1.25s;
      background-color: #ecf0f3;
      overflow: hidden;
      box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
      text-align: center;
      .top-cricle {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #ecf0f3;
        box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
        top: -80%;
        right: -30%;
        transition: 1.25s;
        z-index: 16;
      }
      .bottom-cricle {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #ecf0f3;
        box-shadow: inset 8px 8px 12px #d1d9e6, inset -8px -8px 12px #f9f9f9;
        bottom: -70%;
        left: -50%;
        transition: 1.25s;
        z-index: 16;
      }
      h1 {
        margin: 20px 0;
        font-size: 34px;
      }
      P {
        color: #a0a5ab;
        line-height: 1.5;
        margin: 20px 0;
      }
    }
    .form-box {
      letter-spacing: 0.15px;
      width: 60%;
      height: 100%;
      position: absolute;
      top: 0;
      transform: translateX(60%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // padding: 0 50px;
      h1 {
        font-size: 34px;
        margin: 30px 0;
      }
      input {
        width: 350px;
        margin: 10px 0;
        padding: 15px 25px;
        font-size: 14px;
        letter-spacing: 0.15px;
        border: none;
        outline: none;
        font-family: "Montserrat", sans-serif;
        background-color: #ecf0f3;
        transition: 0.25s ease;
        border-radius: 8px;
        box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
      }
      .input-row {
        width: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 180px;
        }
        .btn {
          padding: 15px 25px;
          margin: 0;
          width: 125px;
          background-color: #4b70e2;
          border-radius: 8px;
          font-size: 13px;
          box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
          &:hover {
            cursor: pointer;
            opacity: 0.85;
          }
        }
      }
      .forget {
        margin: 20px 0 0;
        color: #181818;
        position: relative;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mobile-contain {
    width: 500px;
    background-color: #ecf0f3;
    box-shadow: 10px 10px 10px #d1d9e6, -10px -10px 10px #f9f9f9;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    padding: 20px;
    display: none;
    .signin-box {
      transition: all 1.25s;
    }
    h1 {
      font-size: 20px;
      font-weight: 500;
      margin: 10px 0;
      text-align: center;
    }
    input {
      width: 350px;
      margin: 10px 0;
      padding: 15px 25px;
      font-size: 14px;
      letter-spacing: 0.15px;
      border: none;
      outline: none;
      font-family: "Montserrat", sans-serif;
      background-color: #ecf0f3;
      transition: 0.25s ease;
      border-radius: 8px;
      box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
    }
    .input-row {
      width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 180px;
      }
      .btn {
        padding: 15px 25px;
        margin: 0;
        width: 125px;
        background-color: #4b70e2;
        border-radius: 8px;
        font-size: 13px;
        box-shadow: inset 2px 2px 4px #d1d9e6, inset -2px -2px 4px #f9f9f9;
        &:hover {
          cursor: pointer;
          opacity: 0.85;
        }
      }
    }
    .forget-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0;
      .forget-btn {
        padding: 0;
        background-color: transparent;
        width: auto;
        color: #142b92;
        margin: 0;
        font-weight: 500;
        text-decoration: underline;
      }
    }
    .forget {
      color: #181818;
      position: relative;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
    .btn {
      margin: 30px auto;
    }
  }
}

.txt-move-to-right {
  animation: 0.5s moveToRight ease-in-out forwards;
}
.txt-move-to-left {
  animation: 0.5s moveToRightLeft ease-in-out forwards;
}
.form-move-to-left {
  animation: 0.5s moveToLeft ease-in-out forwards;
}
.form-move-to-right {
  animation: 0.5s moveToLeftRight ease-in-out forwards;
}

.mobile-blur {
  // animation: 0.5s blur ease-in-out forwards;
}

@keyframes moveToRight {
  0% {
    transform: translateX(-6%);
    filter: blur(3px);
  }
  50% {
    filter: blur(2px);
  }
  100% {
    transform: translateX(146%);
    box-shadow: -4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  }
}

@keyframes moveToRightLeft {
  0% {
    transform: translateX(146%);
    filter: blur(3px);
  }
  50% {
    filter: blur(2px);
  }
  100% {
    transform: translateX(-6%);
    box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  }
}

@keyframes moveToLeft {
  0% {
    transform: translateX(60%);
    filter: blur(3px);
  }
  50% {
    filter: blur(2px);
  }
  100% {
    transform: translateX(-5%);
    box-shadow: -4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  }
}

@keyframes blur {
  0% {
    filter: blur(3px);
  }
  50% {
    filter: blur(2px);
  }
  100% {
    filter: blur(0px);
  }
}

@keyframes moveToLeftRight {
  0% {
    transform: translateX(-5%);

    filter: blur(3px);
  }
  50% {
    filter: blur(2px);
  }
  100% {
    transform: translateX(60%);
    box-shadow: -4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
  }
}

@media screen and (max-width: 1000px) {
  .userLogin {
    padding: 10px;
    .contain {
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .userLogin {
    .contain {
      display: none;
    }
    .mobile-contain {
      display: block;
    }
  }
}

@media screen and (max-width: 500px) {
  .userLogin {
    .mobile-contain {
      width: 100%;
      .input-row {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }
}
