@import "./common/common.scss";

.percent-toolbar {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 0 10px;
  padding: 10px 0;

  .logo {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(33, 33, 33, 0.2) 0px 10px 15px -3px,
      rgba(33, 33, 33, 0.2) 0px 4px 6px -4px;
    background: linear-gradient(to right top, rgb(33, 33, 33), rgb(66, 66, 66));
  }

  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(66, 66, 66);
    margin-right: 30px;
    border-radius: 10px;

    input {
      width: 200px;
      padding: 10px;
      background-color: transparent;
      border: 0;

      &:focus {
        outline: none;
      }
    }
  }

  .statuBar {
    padding: 15px 5px;
    border-radius: 50px;
    box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(78, 149, 185, 0.9);
    text-transform: uppercase;
    background-color: #ecf0f3;
    box-shadow: 15px 15px 30px #d1d9e6, -15px -15px 30px #ffffff,
      inset 0px 0px 0px 0px #d1d9e6, inset 0px 0px 0px 0px #ffffff;
    -webkit-tap-highlight-color: transparent;

    .btn-p {
      width: 100px;
      height: 100%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      margin: 0 10px;

      &:hover {
        cursor: pointer;
      }
    }

    .btn {
      position: absolute;
      top: 5px;
      left: 50%;
      min-width: 100px;
      padding: 10px;
      border-radius: 25px;
      background: #d0e4f3;
      color: #4e95b9;
      font-weight: 600;
      box-shadow: 3px 3px 7px #d1d9e6, -3px -3px 7px #ffffff;
      transition: all 0.3s ease-in-out;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .move1 {
      transform: translateX(80%);
    }

    .move2 {
      transform: translateX(-155%);
    }
  }
}

.percent-page {
  border-radius: 20px;
  // box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  overflow: hidden;
  .toolbar {
    height: 80px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgb(118, 135, 243) 0%,
      rgba(70, 120, 230, 0.5) 100%
    );

    .site-select {
      width: 250px;
    }

    .css-13cymwt-control {
      height: 30px;
      padding: 0px 15px;
      background-color: transparent;
      border-color: transparent;
    }

    .css-1jqq78o-placeholder {
      font-size: 20px;
      background-color: transparent;
      border-color: transparent;
    }

    .css-1xc3v61-indicatorContainer {
      // color: #469fe7;
      // color: #fff;
    }

    .css-1dimb5e-singleValue {
      // color: #469fe7;
      // color: #fff;
      font-size: 16px;
      font-weight: 500;
    }

    .css-t3ipsp-control {
      height: 30px;
      // border-radius: 25px;
      padding: 0 15px;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        border: 0;
      }

      &:focus {
        border: 0;
      }
    }

    .css-1u9des2-indicatorSeparator {
      width: 0;
    }

    .css-15lsz6c-indicatorContainer {
      color: #469fe7;
    }

    .css-dev-only-do-not-override-1qfezbu {
      color: #469fe7;
      font-size: 20px;
    }

    .right {
      display: flex;
      align-items: center;
    }

    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      input {
        width: 200px;
        height: 100%;
        padding: 5px 10px;
        background-color: transparent;
        border: 0;
        color: #fff;

        &::placeholder {
          color: #fff;
        }

        border-bottom: 2px solid #fff;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .head {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-weight: 600;
    background-color: #fff;

    .left {
      display: flex;
      align-items: center;
      padding-left: 30px;

      .status {
        width: 100px;
      }

      .name {
        width: 100px;
      }

      .price {
        width: 100px;
      }

      .product {
        width: 200px;
      }

      .sku {
        width: 100px;
      }

      .rate {
        width: 100px;
      }

      .description {
        width: 200px;
      }

      .total {
        width: 100px;
      }

      .time {
        width: 200px;
      }
    }

    .tool {
      width: 100px;
    }
  }

  .body {
    .li {
      min-height: 60px;
      margin: 10px 0 0;
      padding: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      position: relative;
      overflow: hidden;

      &:hover {
        opacity: 0.9;
        cursor: pointer;
      }

      .type {
        position: absolute;
        top: 2px;
        left: -25px;
        padding: 5px 0;
        width: 100px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        transform: rotate(330deg);
        opacity: 0.65;
      }

      .private {
        background-color: rgb(235, 94, 40);
      }

      .personal {
        background-color: rgb(243, 187, 69);
      }

      .public {
        background-color: rgb(122, 194, 154);
      }

      .left {
        display: flex;
        align-items: center;
        text-align: center;
        padding-left: 30px;

        .status {
          width: 100px;

          .state-btn {
            padding: 8px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
          }
        }

        .pending {
          background-color: #a5a5a5;
        }

        .processing {
          background-color: #58bbe2;
        }

        .successed {
          background-color: #54af4c;
        }

        .failed {
          background-color: #f44336;
        }

        .name {
          width: 100px;
        }

        .total {
          width: 100px;
        }

        .price {
          width: 100px;
          color: rgba(255, 0, 0, 0.5);
          font-weight: 650;
        }

        .rate {
          width: 100px;
          font-weight: 650;
          color: rgba(10, 88, 20);
        }

        .product {
          width: 200px;
          padding: 0 5px;
          word-wrap: break-word;
        }

        .sku {
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          img {
            height: 15px;
          }

          .sku-p {
            font-size: 12px;
            padding: 5px;
          }
        }

        .description {
          width: 200px;
          padding: 0 5px;
          word-wrap: break-word;
        }

        .time {
          width: 200px;
        }
      }

      .tool {
        width: 100px;
        padding: 0 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }

    .mobile-li {
      color: #697a8d;
      font-size: 14px;
      display: none;
      margin: 10px 0 0;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);

      .row {
        display: flex;
        padding: 10px 0;
        align-items: center;
        position: relative;

        .type {
          position: absolute;
          top: 10px;
          left: -31px;
          padding: 5px 0;
          width: 100px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          transform: rotate(309deg);
          opacity: 0.65;
        }

        .private {
          background-color: rgb(235, 94, 40);
        }

        .personal {
          background-color: rgb(243, 187, 69);
        }

        .public {
          background-color: rgb(122, 194, 154);
        }

        .pending {
          background-color: #a5a5a5;
        }

        .processing {
          background-color: #58bbe2;
        }

        .successed {
          background-color: #54af4c;
        }

        .failed {
          background-color: #f44336;
        }

        .left {
          margin-right: 10px;
          padding: 10px;

          .img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50px;

            img {
              max-width: 100%;
            }
          }
        }

        .right {
          .r-row {
            display: flex;
            align-items: center;
            margin: 7px 0;
            font-weight: 550;
            font-size: 14px;

            i {
              margin: 0 10px;
            }

            .value {
              padding: 0 5px;
              word-wrap: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              /* 以下样式实现多行省略 */
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;

              /* 限制为两行 */
              span {
                color: #e60f0f;
              }

              .on {
                color: #53e60f;
              }

              .off {
                color: #e60f0f;
              }

              .sku {
                display: flex;
                align-items: center;

                img {
                  height: 15px;
                  margin-right: 5px;
                }
              }
            }
            .rate {
              color: rgba(10, 88, 20);
            }
            .type {
              color: #e6700f;
            }

            .state {
              width: 100px;
              padding: 0 5px;

              .state-btn {
                padding: 8px;
                border-radius: 50px;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                color: #fff;
              }
            }
          }
        }

        .btn {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
    }

    .no-content {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 500px;
    }
  }
}

.footer {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .MuiPaginationItem-page.Mui-selected {
    background: rgba(70, 120, 230);
    border-color: rgba(70, 120, 230);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(25, 29, 253, 0.4);
    color: #fff;
  }
}

.icon {
  transition: all 0.3s;

  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
}

.img-box {
  &:hover {
    cursor: pointer;
  }

  img {
    height: 20px;
  }

  video {
    height: 20px;
  }
}

.isOpen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.055);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  img {
    transform: scale(40);
  }

  video {
    transform: scale(40);
  }
}

@media screen and (max-width: 1200px) {
  .percent-page {
    overflow-x: scroll;

    .head {
      font-size: 14px;

      .left {
        .product {
          width: 150px;
        }

        .description {
          width: 150px;
        }

        .time {
          width: 100px;
        }
      }
    }

    .body {
      .li {
        font-size: 14px;

        .left {
          .product {
            width: 150px;
          }

          .description {
            width: 150px;
          }

          .time {
            width: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .percent-page {
    overflow-x: scroll;

    .head {
      font-size: 12px;

      .left {
        .product {
          width: 120px;
        }

        .description {
          width: 120px;
        }

        .price {
          width: 80px;
        }

        .name {
          width: 80px;
        }

        .time {
          width: 100px;
        }
      }
    }

    .body {
      .li {
        font-size: 13px;

        .left {
          .product {
            width: 120px;
          }

          .description {
            width: 120px;
          }

          .price {
            width: 80px;
          }

          .name {
            width: 80px;
          }

          .status {
            .state-btn {
              font-size: 12px;
            }
          }

          .time {
            width: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .percent-page {
    border-radius: 0;
    .head {
      display: none;
    }
    .body {
      .li {
        display: none;
      }
      .mobile-li {
        display: block;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .percent-toolbar {
    justify-content: center;
  }
}
