@import "./common/common.scss";
.editBilling-page {
  width: 1000px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  padding: 50px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
  overflow: hidden;
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .type {
    position: absolute;
    top: 12px;
    left: -25px;
    padding: 5px 0;
    width: 150px;
    text-align: center;
    color: #fff;
    // font-size: 12px;
    transform: rotate(330deg);
  }
  .withdraw {
    background-color: rgb(197, 77, 56);
  }
  .charge {
    background-color: rgb(115, 170, 49);
  }
  .row {
    width: 800px;
    margin: 20px 0;
    letter-spacing: 1.5px;
    .title-row {
      display: flex;
      align-items: center;
      font-weight: 600;
      .title {
        min-width: 150px;
        margin-right: 30px;
      }
      .value {
        display: flex;
        align-items: center;
        img {
          height: 20px;
          margin-left: 10px;
        }
      }
      textarea {
        border: 1px solid rgb(232, 237, 242);
        padding: 15px;
        border-radius: 10px;
        min-height: 200px;
        width: 60%;
      }
    }

    input {
      width: 100%;
      border: 1px solid rgb(232, 237, 242);
      padding: 15px;
      border-radius: 10px;
      font-size: 18px;
      font-weight: 600;
      color: #697a8d;
      &:focus {
        border: 2px solid rgb(69, 103, 216);
        outline: none;
      }
    }
    .file {
      position: relative;
      padding: 30px;
      color: #6c6e6e;
      border: 3px dashed rgb(232, 237, 242);
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .file-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        &:hover {
          cursor: pointer;
        }
      }
      .p {
        margin: 10px 0 0;
        letter-spacing: 2px;
        line-height: 1.5;
        font-weight: 600;
      }
      .img {
        height: 50px;
        img {
          height: 100%;
        }
      }
    }
    .control {
      font-size: 16px;
      font-weight: 600;
      color: #697a8d;
      width: 100%;
      height: 50px;
    }
    .statuBar {
      width: 100px;
      height: 50px;
      border: 2px solid rgb(69, 103, 216);
      border-radius: 25px;
      // background-color: #fff;
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .btn-p {
        width: 100%;
        height: 100%;
        color: rgb(69, 103, 216);
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        &:hover {
          cursor: pointer;
        }
      }

      .btn {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 40px;
        height: 40px;
        border-radius: 25px;
        background-color: rgb(69, 103, 216);
        box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
        transition: all 0.3s ease-in-out;
      }
      .move {
        transform: translateX(50px);
      }
    }
  }
  .footer {
    margin: 50px 0;
    display: flex;
    justify-content: center;
    .btn {
      background-color: rgb(69, 103, 216);
      box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
      padding: 10px;
      color: #fff;
      border-radius: 5px;
      transition: all 0.3s;
      margin: 0 10px;
      &:hover {
        cursor: pointer;
        opacity: 0.85;
      }
    }
    .success {
      background-color: #4caf50;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(76, 175, 80, 0.4);
    }
    .fail {
      background-color: #f44336;
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(244, 67, 54, 0.4);
    }
  }
}
.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}

@media screen and (max-width: 1000px) {
  .editBilling-page {
    width: 100%;
    padding: 50px 30px;
    .row {
      width: 100%;
    }
  }
}
@media screen and (max-width: 600px) {
  .editBilling-page {
    width: 100%;
    padding: 50px 20px;
    font-size: 13px;
    .row {
      width: 100%;
      .title-row {
        flex-direction: column;
        justify-content: start;
        align-items: start;
        .title {
          margin-bottom: 10px;
        }
        .value {
          width: 100%;
          color: $background;
          textarea {
            width: 100%;
          }
        }
      }
    }
  }
}
