@import "./common/common.scss";
.billing-toolbar {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 0 10px;
  padding: 10px 0;
  .logo {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(33, 33, 33, 0.2) 0px 10px 15px -3px,
      rgba(33, 33, 33, 0.2) 0px 4px 6px -4px;
    background: linear-gradient(to right top, rgb(33, 33, 33), rgb(66, 66, 66));
  }
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(66, 66, 66);
    margin-right: 30px;
    border-radius: 10px;
    input {
      width: 200px;
      padding: 10px;
      background-color: transparent;
      border: 0;
      &:focus {
        outline: none;
      }
    }
  }
  .statuBar {
    padding: 15px 5px;
    border-radius: 50px;
    box-shadow: 7px 11px 10px rgba(120, 130, 140, 0.13);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(78, 149, 185, 0.9);
    text-transform: uppercase;
    background-color: #ecf0f3;
    box-shadow: 15px 15px 30px #d1d9e6, -15px -15px 30px #ffffff,
      inset 0px 0px 0px 0px #d1d9e6, inset 0px 0px 0px 0px #ffffff;
    -webkit-tap-highlight-color: transparent;
    .btn-p {
      width: 100px;
      height: 100%;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      margin: 0 10px;
      &:hover {
        cursor: pointer;
      }
    }

    .btn {
      position: absolute;
      top: 5px;
      left: 50%;
      min-width: 100px;
      padding: 10px;
      border-radius: 25px;
      background: #d0e4f3;
      color: #4e95b9;
      font-weight: 600;
      box-shadow: 3px 3px 7px #d1d9e6, -3px -3px 7px #ffffff;
      transition: all 0.3s ease-in-out;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .move1 {
      transform: translateX(55%);
    }
    .move2 {
      transform: translateX(-170%);
    }
  }
}
.billing-page {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    font-size: 16px;
    font-weight: 700;
    color: rgb(66, 66, 66);
    // border-bottom: 1px solid #e8edf2;
    background-color: #fff;
    text-align: center;
    .left {
      display: flex;
      align-items: center;
      padding-left: 30px;
      .state {
        width: 100px;
      }
      .name {
        width: 150px;
      }
      .price {
        width: 100px;
      }
      .time {
        width: 200px;
      }
      .description {
        width: 300px;
      }
    }
    .tool {
      width: 150px;
    }
  }
  .body {
    .li {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      position: relative;
      margin: 10px 0 0;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      .left {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: #697a8d;
        padding-left: 30px;
        .icon {
          position: absolute;
          top: 10px;
          left: -25px;
          padding: 5px 0;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-align: center;
          font-size: 12px;
          transform: rotate(315deg);
          opacity: 0.65;
        }
        .state {
          width: 100px;
          padding: 0 5px;
          .state-btn {
            padding: 8px;
            border-radius: 50px;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            color: #fff;
          }
        }
        .pending {
          background-color: #a5a5a5;
        }
        .processing {
          background-color: #58bbe2;
        }
        .successed {
          background-color: #54af4c;
        }
        .failed {
          background-color: #f44336;
        }
        .withdraw {
          background-color: rgb(197, 77, 56);
        }
        .charge {
          background-color: rgb(115, 170, 49);
        }
        .name {
          width: 150px;
        }
        .price {
          width: 100px;
          color: rgba(255, 0, 0, 0.5);
          font-weight: 650;
        }
        .description {
          width: 300px;
          word-wrap: break-word;
          padding: 0 5px;
        }
        .time {
          width: 200px;
        }
      }
      .tool {
        width: 150px;
        padding: 0 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    .mobile-li {
      color: #697a8d;
      font-size: 14px;
      display: none;
      margin: 10px 0 0;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      .row {
        display: flex;
        padding: 10px 0;
        align-items: center;
        position: relative;
        .type-icon {
          position: absolute;
          top: 16px;
          left: -25px;
          padding: 5px 0;
          width: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          text-align: center;
          font-size: 12px;
          transform: rotate(315deg);
          opacity: 0.65;
        }
        .pending {
          background-color: #a5a5a5;
        }
        .processing {
          background-color: #58bbe2;
        }
        .successed {
          background-color: #54af4c;
        }
        .failed {
          background-color: #f44336;
        }
        .withdraw {
          background-color: rgb(197, 77, 56);
        }
        .charge {
          background-color: rgb(115, 170, 49);
        }
        .left {
          margin-right: 10px;
          padding: 10px;
          .img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            overflow: hidden;
            border-radius: 50px;
            img {
              max-width: 100%;
            }
          }
        }
        .right {
          .r-row {
            display: flex;
            align-items: center;
            margin: 7px 0;
            font-weight: 550;
            font-size: 14px;
            i {
              margin: 0 10px;
            }
            .value {
              span {
                color: #e60f0f;
              }
              .on {
                color: #53e60f;
              }
              .off {
                color: #e60f0f;
              }
            }
            .type {
              color: #e6700f;
            }
            .state {
              width: 100px;
              padding: 0 5px;
              .state-btn {
                padding: 8px;
                border-radius: 50px;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                color: #fff;
              }
            }
          }
        }
        .btn {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }
    }
    .no-content {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 500px;
    }
  }
}
.footer {
  margin: 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiPaginationItem-page.Mui-selected {
    background: rgba(70, 120, 230);
    border-color: rgba(70, 120, 230);
    box-shadow: 0 0.625rem 1.25rem 0 rgba(25, 29, 253, 0.4);
    color: #fff;
  }
}

.icon {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.15);
    cursor: pointer;
  }
}

@media screen and (max-width: 1200px) {
  .billing-page {
    overflow-x: scroll;
    .head {
      font-size: 14px;
      .left {
        .name {
          width: 100px;
        }
        .price {
          width: 100px;
        }
        .description {
          width: 200px;
        }
        .time {
          width: 120px;
        }
      }
    }
    .body {
      .li {
        font-size: 14px;
        .left {
          .name {
            width: 100px;
          }
          .price {
            width: 100px;
          }
          .description {
            width: 200px;
          }
          .time {
            width: 120px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .billing-page {
    overflow-x: scroll;
    .head {
      font-size: 12px;
      .left {
        .product {
          width: 120px;
        }
        .description {
          width: 120px;
        }
        .price {
          width: 80px;
        }
        .name {
          width: 80px;
        }
        .time {
          width: 100px;
        }
      }
    }
    .body {
      .li {
        font-size: 13px;
        .left {
          .product {
            width: 120px;
          }
          .description {
            width: 120px;
          }
          .price {
            width: 80px;
          }
          .name {
            width: 80px;
          }
          .status {
            .state-btn {
              font-size: 12px;
            }
          }
          .time {
            width: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .billing-page {
    .head {
      display: none;
    }
    .body {
      .li {
        display: none;
      }
      .mobile-li {
        display: block;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .billing-toolbar {
    justify-content: center;
  }
}
