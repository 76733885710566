.alert-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  display: none;
  justify-content: center;
  align-items: center;
  .alert-box {
    width: 350px;
    padding: 50px 50px 20px;
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    box-shadow: 0rem 0.3125rem 20rem 20rem rgba(82, 63, 105, 0.05);
    .close {
      position: absolute;
      top: 15px;
      right: 15px;
    }
    .title {
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      letter-spacing: 1.5px;
    }
    .btn-row {
      margin: 20px 0 0;
      display: flex;
      justify-content: end;
      align-items: center;
      .btn {
        padding: 5px 15px;
        background-color: rgb(10, 95, 29);
        border-radius: 5px;
        color: #fff;
        &:hover {
          cursor: pointer;
          opacity: 0.85;
        }
      }
    }
  }
}

.alert-show {
  display: flex !important;
}
